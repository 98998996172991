import personalPlacesData from './testPersonalPlaces.json';
import sessionPlacesData from './testSessionPlaces.json';
import loginCookieData from './testLoginCookie.json';

const SET_USE_TEST_DATA = true;

const isLocalHost = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
const useTestData = isLocalHost ? SET_USE_TEST_DATA : false

function getTestPersonalPlaces() {
    return personalPlacesData['relatives'];
}

function getTestSessionPlaces() {
    let places = sessionPlacesData['session-places'];
    let points = [];
    for (let place of places) {
        const curPoint = [place.latitude, place.longitude];
        points.push(curPoint);
    }
    return points;
}

function getTestLoginCookie() {
    const cookie = loginCookieData['cookie'];
    return cookie;
}

export { useTestData, getTestPersonalPlaces, getTestSessionPlaces, getTestLoginCookie };