export function buildApiUrl(apiEndpoint) {
  let apiUrl = new URL(window.location.href);
  apiUrl.hostname = "api." + apiUrl.hostname.replace("www.", "");
  apiUrl.pathname = apiEndpoint;
  apiUrl.search = "";
  apiUrl = apiUrl.toString();
  return apiUrl;
}

export function getParams(queryParams, paramsToCheck) {
  let missingParams = [];
  let cookie = {};

  paramsToCheck.forEach((param) => {
    if (!queryParams.has(param)) missingParams.push(param);
    else cookie[param] = queryParams.get(param);
  });

  return { missingParams, cookie };
}

// export async function getCurCoords() {
//     if ("geolocation" in navigator) {
//         const successCallBack = (position) => {
//             const lat = position.coords.latitude;
//             const lon = position.coords.longitude;
//             return {'latitude': lat, 'longitude': lon};
//         };

//         const errorCallBack = (error) => {
//             console.error("Error getting user's current location:", error);
//             return null;
//         };

//         navigator.geolocation.getCurrentPosition(successCallBack, errorCallBack);
//     } else {
//         console.error("Geolocation is not supported by this browser.");
//         return null;
//     }
// }

// export async function placeToCoords(place, bingKey) {
//     place.replace(',','%2C').replace(' ','%20');
//     const apiUrl = `http://dev.virtualearth.net/REST/v1/Locations?query=${place}&includeNeighborhood=0&key=${bingKey}`;
//     const response = await fetch(apiUrl);
//     const jsonData = await response.json();
//     try {
//         const geo = jsonData['resourceSets'][0]['resources'][0];
//         standardGeo = {
//             'box': geo['bbox'],
//             'coords': geo['point']['coordinates'],
//             'name': geo['name']
//         }
//         return standardGeo['coords'];
//     } catch (error) {
//         console.error("Could not retrieve the latitude and longitude for the location: ", loc);
//         return null;
//     }
// }
