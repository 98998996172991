import React from "react";
import QRCode from "react-qr-code";
import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import "./host.css";
import Cookies from "universal-cookie";
import { generateUnusedSessionId } from "../../../presenter/hostPresenter";
import { MapComponent } from "../../components/mapComponent/mapComponent";
import { getSessionPlaces } from "../../../model/services/api";
import { Loading } from "../../components/loading/loading";
import Modal from "../../components/Modal/Modal";
import { HOST_SESSION_ID_COOKIE_KEY } from "../hostSession/hostSession";

const REFRESH_TIME = 30000; // 30 seconds

/**
 * This React component will:
 * 1. allow hosts to create sessions
 * 2. ? something about displaying a map of the session ?
 */
export function Host() {
  const [points, setPoints] = useState();
  const [sessionId, setSessionId] = useState();
  const [largeQRCode, setLargeQRCode] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const sessionUrl = sessionId ? buildSessionUrl(sessionId) : "";

  useEffect(
    function onStart() {
      (async function () {
        try {
          const cookies = new Cookies();
          const hostSessionIdCookie = cookies.get(HOST_SESSION_ID_COOKIE_KEY);

          let sessionId = "";
          if (hostSessionIdCookie)
            sessionId = hostSessionIdCookie["host-session-id"];
          else {
            sessionId = await generateUnusedSessionId();
            cookies.set(HOST_SESSION_ID_COOKIE_KEY, {
              "host-session-id": sessionId,
            });
          }
          const sessionPlaces = await getSessionPlaces(sessionId);

          setSessionId(sessionId);
          setPoints(sessionPlaces);

          setInterval(async function updateSessionPlaces() {
            const sessionPlaces = await getSessionPlaces(sessionId);
            setPoints(sessionPlaces);
          }, REFRESH_TIME);
        } catch (error) {
          showBoundary(error);
        }
      })();
    },
    [showBoundary]
  );

  function handleExit(e) {
    const cookies = new Cookies();
    cookies.remove(HOST_SESSION_ID_COOKIE_KEY);
    window.location.href = "/";
  }

  function handleGoHome(e) {
    window.location.href = "/";
  }

  return (
    <div className="host-page">
      <header>
        <h1 className="ui">
          <div id="logo" onClick={handleGoHome}>
            Our<span id="beg">Beginnings</span>
          </div>
          <span className="session-id-label">Session ID:</span>
          <div className="row">
            <span className="session-id">{sessionId}</span>
            <div
              className={"qr-wrapper" + (largeQRCode ? " large" : "")}
              onClick={() => setLargeQRCode(!largeQRCode)}
            >
              <QRCode size={256} value={sessionUrl} viewBox={`0 0 256 256`} />
            </div>
          </div>
        </h1>
      </header>

      <ShowMap sessionId={sessionId} points={points} />
      {!points && <Loading message="Loading map" />}

      <button
        onClick={() => setShowMenu(true)}
        className="menu-button material-symbols-outlined"
      >
        close
      </button>
      <Modal
        className="ui"
        show={showMenu}
        closeFunction={() => setShowMenu(false)}
      >
        <div className="grid">
          <div className="modal-text">
            Are you sure you want to exit the session? You will need to re-enter the current session ID to rejoin this session.
          </div>
          <button id="confirm-btn" onClick={handleExit}>Exit Session</button>
          <button id="cancel-btn" onClick={() => setShowMenu(false)}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
}

function buildSessionUrl(sessionId) {
  let url = new URL(window.location.href);
  url.pathname = "user-session";
  url.searchParams.append("session-id", sessionId);
  return url.toString();
}

function ShowMap(props) {
  const points = props.points;
  return (
    <MapComponent
      className="map"
      markerPoints={{}}
      heatmapPoints={points ? points : []}
      isHost={true}
    />
  );
}
