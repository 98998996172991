import React, { useState } from "react";
import Modal from "../../components/Modal/Modal";
import "./home.css";
import Cookies from "universal-cookie";
import { HOST_SESSION_ID_COOKIE_KEY } from "../hostSession/hostSession";

/**
 * This React component will:
 * 1. show two buttons to user to navigate to other components
 *    JOIN - - "join session" - navigate to Login component
 *    CREATE - - "create session" - navigate to Host component
 */
export function Home() {
  const [showMenu, setShowMenu] = useState(false);

  function handleJoin(e) {
    window.location.href = "/login";
  }

  function handleHostCreateSession(e) {
    window.location.href = "/host";
  }

  function handleHostExistingSession(e) {
    window.location.href = "/host-session";
  }

  function clickHostSession() {
    const cookies = new Cookies();
    const hostSessionCookie = cookies.get(HOST_SESSION_ID_COOKIE_KEY);
    if (hostSessionCookie) {
      window.location.href = "/host";
    } else {
      setShowMenu(true)
    }
  }

  return (
    <div className="home-container">
      <h1>
        Our<span id="beg">Beginnings</span>
      </h1>
      <button 
        className="btn" 
        id="primary" 
        onClick={handleJoin}
      >
        Join Session
      </button>
      <button 
        className="btn" 
        id="secondary" 
        onClick={clickHostSession}
      >
        Host Session
      </button>
      <Modal
        className="host-menu-modal"
        show={showMenu}
        closeFunction={() => setShowMenu(false)}
      >
        <div className="grid">
        <button
            id="primary" 
            onClick={(e) => {
              setShowMenu(false);
              handleHostExistingSession(e);
            }}
          >
            View Existing Session
          </button>
          <button
            id="secondary" 
            onClick={(e) => {
              setShowMenu(false);
              handleHostCreateSession(e);
            }}
          >
            Create New Session
          </button>
          <button id="close-btn" onClick={() => setShowMenu(false)}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
}
