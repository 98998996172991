const rootEl = document.querySelector(":root");

export default function assign100dvh() {
  if (!rootEl.style["--viewport-height"]) {
    setVar();
    window.addEventListener("resize", setVar);
  }
}

function setVar() {
  const viewportHeight = window.innerHeight;
  rootEl.style.setProperty("--viewport-height", viewportHeight + "px");
}
