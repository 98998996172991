export function parseFSData(data) {
    let relatives = getAllRelatives(data);
    const locations = getAllLocations(data);
    relatives = convertPlacesToCoords(relatives, locations);
    return relatives;
}

function getAllRelatives(data) {
  const relatives = [];

  if (!("persons" in data))
    throw new Error(
      'The key word "persons" was not found in the FamilySearch response.'
    );
  const persons = data["persons"];

  for (let i = 0; i < persons.length; i++) {
    if (!("id" in persons[i])) throw new Error(errMsg("id", `person ${i}`));
    const pid = persons[i]["id"];

    if (!("display" in persons[i]))
      throw new Error(errMsg("display", `person ${i}`));
    const display = persons[i]["display"];

    if (!("name" in display)) throw new Error(errMsg("name", `person ${i}`));
    // if (!('birthPlace' in display)) throw new Error(errMsg('birthPlace', `person ${i}`));
    if (!("ascendancyNumber" in display))
      throw new Error(errMsg("ascendancyNumber", `person ${i}`));

    const ascNum = display["ascendancyNumber"];
    const spouseAscNum = "1-S";
    // check if ascendencyNumber is a spouse
    // if so move to next iteration
    if (ascNum === spouseAscNum) {
      continue;
    } else if ("birthPlace" in display) {
      const birthplace = display["birthPlace"];
      const relativeName = display["name"];

      relatives.push({
        pid: pid,
        name: relativeName,
        ascNum: Number(ascNum),
        birthplace: birthplace,
      });
    }
  }

  return relatives;
}

function getAllLocations(data) {
  const locations = {};

  if (!("places" in data))
    throw new Error(
      'The key word "places" was not found in the FamilySearch response.'
    );
  const places = data["places"];

  for (let i = 0; i < places.length; i++) {
    if (!("names" in places[i]))
      throw new Error(errMsg("names", `location ${i}`));
    if (!("latitude" in places[i]))
      throw new Error(errMsg("latitude", `location ${i}`));
    if (!("longitude" in places[i]))
      throw new Error(errMsg("longitude", `location ${i}`));
    const names = places[i]["names"];
    const lat = places[i]["latitude"];
    const lon = places[i]["longitude"];

    for (let j = 0; j < names.length; j++) {
      if (!("value" in names[j]))
        throw new Error(errMsg("value", `name ${i} of location ${j}`));
      const value = names[j]["value"];
      locations[value] = { latitude: lat, longitude: lon };
    }
  }

  return locations;
}

function convertPlacesToCoords(relatives, locations) {
  const updatedRelatives = [];

  for (let rel of relatives) {
    if (rel.birthplace in locations) {
      const coords = locations[rel.birthplace];
      rel["latitude"] = coords["latitude"];
      rel["longitude"] = coords["longitude"];
      updatedRelatives.push(rel);
    }
  }

  return updatedRelatives;
}

function errMsg(keyWord, foundFor) {
  return `The key word "${keyWord}" was not found for ${foundFor} in the FamilySearch response.`;
}