import React from "react";
import { Session } from "../../components/session/session";

const HOST_SESSION_ID_COOKIE_KEY = "host-session-id";
const NUM_BOXES = 6;

/**
 * This React page will:
 * 1. (compose Session component)
 */
export function HostSession() {
  function navNextPage() {
    window.location.href = "/host";
  }

  return (
    <Session 
        numBoxes={NUM_BOXES}
        navNextPage={navNextPage}
        cookieName={HOST_SESSION_ID_COOKIE_KEY} 
    />
  );
}

export { HOST_SESSION_ID_COOKIE_KEY };
