export class Relative {
  constructor(pid, name, relationship, birthplace, birthLocation) {
    this.pid = pid;
    this.name = name;
    this.relationship = relationship;
    this.birthplace = birthplace;
    this.birthLocation = birthLocation;
  }
  static objectInstance(rel) {
    return new Relative(
      rel.pid,
      rel.name,
      rel.relationship,
      rel.birthplace,
      rel.birthLocation
    );
  }

  getCoords() {
    return this.birthLocation.getCoords();
  }
}
