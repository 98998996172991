import React, { useState } from "react"
import { postBugReport } from "../../../model/services/api";
import { useErrorBoundary } from "react-error-boundary";

export function ReportBug() {
    const { showBoundary } = useErrorBoundary();
    const [reportTitle, setReportTitle] = useState('');
    const [reportMessage, setReportMessage] = useState('');

    const handleTitleChange = (event) => {
        setReportTitle(event.target.value);
    }

    const handleMessageChange = (event) => {
        setReportMessage(event.target.value);
    }

    const sendReportToLambda = () => {
        postBugReport(reportTitle, reportMessage);
    }

    const throwErrorForReport = () => {
        /* 
        a regular Error throw won't work in event handles or async functions
        use showBoundary in those cases to throw the error and have it caught by ErrorBoundary
        */
       
        showBoundary(new Error("TEST MESSAGE", {cause: {message: "TEST CAUSE", stack: "TEST STACK"}}))
    }

    return (
        <>
            <div>
                <h1>Bug Report Test Page</h1>
                <h2>Test bug reporter</h2>
                <form>
                    <label>Title:</label>
                    <br></br>
                    <input type="text" value={reportTitle} onChange={handleTitleChange}></input>
                    <br></br>
                    <br></br>
                    <label>Message:</label>
                    <br></br>
                    <textarea type="text" value={reportMessage} onChange={handleMessageChange}></textarea>
                    <br></br>
                    <input onClick={sendReportToLambda} type="submit" value="Submit"></input>
                </form>
            </div>
            <div>
                <br></br>
                <br></br>
                <h2>Test Error thrown and reported</h2>
                <button onClick={throwErrorForReport}>Simulate bug report</button>
            </div>
        </>
    )
}