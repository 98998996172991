import { useEffect, useRef } from "react";

export default function Modal({ show, closeFunction, children, ...elProps }) {
  const modalEl = useRef();
  useEffect(() => {
    if (show) modalEl.current.showModal();
    else modalEl.current.close();
  }, [show, modalEl]);

  function onClick(e) {
    if (e.target !== e.currentTarget) return;
    closeFunction();
  }

  return (
    <dialog onClick={onClick} ref={modalEl} {...elProps}>
      {children}
    </dialog>
  );
}
