import React, { useEffect, useState } from "react";
import { Session } from "../../components/session/session";
import Cookies from "universal-cookie";
import { LOGIN_COOKIE_KEY } from "../login/login";
import { getParams } from "../../../presenter/utils.js";

const SESSION_ID_COOKIE_KEY = "session-id";
const NUM_BOXES = 6;

/**
 * This React page will:
 * 1. check if there is sessionCookie
 *    IF SO - - navigate to Map component (/map)
 *    IF NOT - - render component
 * 2. (compose Session component)
 */
export function UserSession() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    function onStart() {
      const cookies = new Cookies();

      // Check query for all parts of cookie from query - - if validated, set the cookie
      if (window.location.search) {
        const queryParams = new URLSearchParams(window.location.search);
        const paramsToCheck = ["session-id"];
        const { missingParams, cookie } = getParams(queryParams, paramsToCheck);

        if (missingParams.length)
          throw new Error(`Cookie info passed in query is invalid -> missing attributes in query (${missingParams})`);
        else cookies.set(SESSION_ID_COOKIE_KEY, cookie);
      }

      // If Login cookie does not exist, go back and login
      const loginCookie = cookies.get(LOGIN_COOKIE_KEY);
      if (!loginCookie) window.location.href = "/login";

      const sessionCookie = cookies.get(SESSION_ID_COOKIE_KEY);
      if (sessionCookie) navNextPage();

      setIsLoading(false);
    }, []
  );

  function navNextPage() {
    window.location.href = "/map";
  }

  return (
    <>
      {!isLoading && 
        <Session 
          numBoxes={NUM_BOXES}
          navNextPage={navNextPage}
          cookieName={SESSION_ID_COOKIE_KEY} 
        />}
    </>
  );
}

export { SESSION_ID_COOKIE_KEY };
