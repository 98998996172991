import { useEffect, useState } from "react";
import { Loading } from "../../components/loading/loading";
import { MapComponent } from "../../components/mapComponent/mapComponent";
import Cookies from "universal-cookie";
import { LOGIN_COOKIE_KEY } from "../login/login";
import { SESSION_ID_COOKIE_KEY } from "../userSession/userSession";
import {
  loadPersonData,
  savePersonData,
} from "../../../presenter/apiPresenter";
import { getBirthplaces } from "../../../model/services/familySearchData";
import { parseFSData } from "../../../presenter/familySearchParse";
import "./map.css";
import Modal from "../../components/Modal/Modal";
import { getSessionPlaces } from "../../../model/services/api";
import { useErrorBoundary } from "react-error-boundary";
import { useTestData } from "../../../data/testData.js";

const FS_REFRESH_COOKIE_KEY = "fs-refresh";
const ONE_DAY_IN_SECS = 86400;
// const REFRESH_TIME = 60000; // 60 seconds

export function Map() {
  const { showBoundary } = useErrorBoundary();
  const [isCheckingCookie, setIsCheckingCookie] = useState(true);
  const [points, setPoints] = useState();
  const [sessionId, setSessionId] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  useEffect(
    function onStart() {
      const generateFSRefreshInfo = (userInfo) => {
        return `${userInfo["pid"]}:${userInfo["session-id"]}`;
      };

      const verifyCookie = (cookie, userInfo) => {
        const fsRefreshInfo = generateFSRefreshInfo(userInfo);
        return Object.hasOwn(cookie, fsRefreshInfo);
      };

      const cookies = new Cookies();
      const loginInfo = cookies.get(LOGIN_COOKIE_KEY);
      const sessionInfo = cookies.get(SESSION_ID_COOKIE_KEY);
      const fsRefreshCookie = cookies.get(FS_REFRESH_COOKIE_KEY);

      if (!loginInfo) window.location.href = "/login";
      else if (!sessionInfo) window.location.href = "/user-session";
      else {
        // Cookies were verified so display UI
        setIsCheckingCookie(false);

        let userInfo = loginInfo;
        userInfo["session-id"] = sessionInfo["session-id"];
        setSessionId(sessionInfo["session-id"]);

        (async function () {
          try {
            // If there is no cookie set for this PID and Session ID, then fetch the FS data and save it
            if (!fsRefreshCookie || !verifyCookie(fsRefreshCookie, userInfo)) {
              if (!useTestData) {
                const rawRelativeData = await getBirthplaces(userInfo);
                const relativeData = parseFSData(rawRelativeData);
                await savePersonData(relativeData, userInfo);
              }

              const fsRefreshInfo = generateFSRefreshInfo(userInfo);
              let cookie = !fsRefreshCookie ? {} : fsRefreshCookie;
              cookie[fsRefreshInfo] = Date.now();
              cookies.set(FS_REFRESH_COOKIE_KEY, cookie, {
                maxAge: ONE_DAY_IN_SECS,
              });
            }

            const points = await loadPersonData(userInfo);
            setPoints(points);
          } catch (error) {
            showBoundary(error);
          }
        })();
      }
    }, [showBoundary]
  );

  function handleExitSession(e) {
    const cookies = new Cookies();
    cookies.remove(SESSION_ID_COOKIE_KEY);
    window.location.href = "/";
  }

  function handleLogout(e) {
    const cookies = new Cookies();
    cookies.remove(LOGIN_COOKIE_KEY);
    window.location.href = "/";
  }

  function handleGoHome(e) {
    window.location.href = "/";
  }

  return (
    <>
      {!isCheckingCookie && (
        <div className="map-page">
          <header className="controls">
            <h1 id="logo-header" onClick={handleGoHome}>
              Our<span id="beg">Beginnings</span>
            </h1>
            <button
              onClick={async (e) => {
                setRefreshing(true);
                if (!useTestData) {
                  const heatmapPoints = await getSessionPlaces(sessionId);
                  setPoints({...points, heatmapPoints});
                  setRefreshing(false); 
                } else {
                  // Set timeout to simulate API fetch so can see refresh animation
                  setTimeout(() => {
                    setRefreshing(false);
                  }, 1500);
                }
              }}
              className="menu-button material-symbols-outlined"
              disabled={refreshing}
            >
              {refreshing ? (
                <div className="loading-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "refresh"
              )}
            </button>
            <button
              onClick={() => setShowMenu(true)}
              className="menu-button material-symbols-outlined"
            >
              close
            </button>
            <Modal
              className="menu-modal"
              show={showMenu}
              closeFunction={() => setShowMenu(false)}
            >
              <div className="grid">
                <button 
                  className="action-btns"
                  onClick={() => {
                    setShowMenu(false);
                    setShowConfirmExit(true);
                  }}
                >
                  Exit Session
                </button>
                <button 
                  className="action-btns"
                  onClick={() => {
                    setShowMenu(false);
                    setShowConfirmLogout(true);
                  }}
                >
                  Logout
                </button>
                <button className="close-btn" onClick={() => setShowMenu(false)}>Cancel</button>
              </div>
            </Modal>
            <Modal
              className="confirm-modal"
              show={showConfirmExit}
              closeFunction={() => setShowConfirmExit(false)}
            >
              <div className="grid">
                <div className="modal-text">
                  Are you sure you want to exit the session? You will need to re-enter the current session ID to rejoin this session.
                </div>
                <button className="action-btns" onClick={handleExitSession}>Exit Session</button>
                <button 
                  className="close-btn"
                  onClick={() => {
                    setShowConfirmExit(false);
                    setShowMenu(true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal>
            <Modal
              className="confirm-modal"
              show={showConfirmLogout}
              closeFunction={() => setShowConfirmLogout(false)}
            >
              <div className="grid">
                <div className="modal-text">
                  Are you sure you want to log out? You will need to login again to join a session.
                </div>
                <button className="action-btns" onClick={handleLogout}>Logout</button>
                <button
                  className="close-btn"
                  onClick={() => {
                    setShowConfirmLogout(false);
                    setShowMenu(true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal>
          </header>
          <span className="session-id">Session: {sessionId}</span>

          <MapComponent
            markerPoints={(points?.personalMarkers) ? points.personalMarkers : {}}
            heatmapPoints={(points?.heatmapPoints) ? points.heatmapPoints : []}
          />
          {!points && <Loading message="Loading map" />}
        </div>
      )}
    </>
  );
}
