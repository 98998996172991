import "./error.css";

export function Error(props) {
  const { error, resetErrorBoundary } = props;

  return (
    <>
      <div className="error-box">
        <div className="error-generic-text">Oops, something went wrong.</div>
        <div className="error-message">{error.message}</div>
        <button className="home-button" onClick={resetErrorBoundary}>Go home</button>
      </div>
    </>
  );
}
