import { buildApiUrl } from "../../presenter/utils";
import { getTestPersonalPlaces, getTestSessionPlaces, useTestData } from "../../data/testData";

const PERSON_DATA_ENDPOINT = "person-data";
const SESSION_ID_ENDPOINT = "session-id";
const SESSION_PLACES_ENDPOINT = "session-places";
const PERSONAL_PLACES_ENDPOINT = "personal-places";
const BUG_REPORT_ENDPOINT = "bug-reporter"

const wait = delay => new Promise((resolve) => {
  setTimeout(resolve, delay);
})

async function postPersonData(personData) {
  const endpoint = buildApiUrl(PERSON_DATA_ENDPOINT);

  try {
    await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(personData),
    });
  } catch (error) {
    console.error("Failed to post person data:", error);
    throw new Error("Failed to post person data:", {cause: error});
  }
  
}

async function sessionIdExists(sessionId) {
  const endpoint = buildApiUrl(SESSION_ID_ENDPOINT);
  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "session-id": sessionId,
      },
    });
    const jsonData = await response.json();
    return jsonData["exists"];
  } catch (error) {
    console.error("Failed to check if the session ID exists:", error);
    throw new Error("Failed to check if the session ID exists:", {cause: error});
  }
  
}

async function getSessionPlaces(sessionId) {
  if (useTestData) {
    await wait(1500); // Simulate time used to fetch
    return getTestSessionPlaces();
  } else {
    const endpoint = buildApiUrl(SESSION_PLACES_ENDPOINT);

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "session-id": sessionId,
        },
      });
      const jsonData = await response.json();
      const places = jsonData["session-places"];
  
      let points = [];
      for (let place of places) {
        const curPoint = [place.latitude, place.longitude];
        points.push(curPoint);
      }
      return points;
    } catch (error) {
      console.error("Failed to get session places:", error);
      throw new Error("Failed to get session places:", {cause: error});
    }
    
  }
}

async function getPersonalPlaces(pid) {
  if (useTestData) {
    await wait(1500); // Simulate time used to fetch
    return getTestPersonalPlaces();
  } else {
    const endpoint = buildApiUrl(PERSONAL_PLACES_ENDPOINT);

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          pid: pid,
        },
      });
      const jsonData = await response.json();
      return jsonData["relatives"];
    } catch (error) {
      console.error("Failed to get personal places:", error);
      throw new Error("Failed to get personal places:", {cause: error});
    }
  }
}

async function postBugReport(errorTitle, errorMessage) {
  if (useTestData) {
    console.log("BUG REPORT: ")
    console.log("TITLE:", errorTitle)
    console.log("BODY:", errorMessage);
  } else {
    const endpoint = buildApiUrl(BUG_REPORT_ENDPOINT);

    try {
      await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"title": errorTitle, "message": errorMessage}),
      });
    } catch (error) {
      console.error("Failed to post bug report:", error);
      throw new Error("Failed to post bug report:", {cause: error});
    }
    
  }
}

export { postPersonData, sessionIdExists, getSessionPlaces, getPersonalPlaces, postBugReport };
