export class User {
  constructor(pid, profileName, email, loginTime, sessionId, relatives) {
    this.pid = pid;
    this.profileName = profileName;
    this.email = email;
    this.loginTime = loginTime;
    this.sessionId = sessionId;
    this.relatives = relatives;
  }
  static objectInstance(user) {
    return new User(
      user.pid,
      user.profileName,
      user.email,
      user.loginTime,
      user.sessionId,
      user.relatives
    );
  }
}
