import "./loading.css";

export function Loading({ message }) {
  return (
    <>
      <div className="loader">
        <div className="loading-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="loading-text">
          <div className="loading-message">{message}</div>
        </div>
        <div className="loading-warning">
          Please do not refresh or navigate away.
        </div>
      </div>
    </>
  );
}
