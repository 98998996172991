import { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import "./mapComponent.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { Relative } from "../../../model/Relative";
import { Location } from "../../../model/Location";

// [TOP/BOTTOM BOUNDARY, LEFT/RIGHT BOUNDARY]
const MAP_BOUNDS = [
  [256, 192],
  [-75, -192],
];

const DESKTOP_MIN_ZOOM = 2.75;
const MOBILE_MIN_ZOOM = 2.25;

function Markers(props) {
  const relatives = props.relatives;
  return Object.keys(relatives).map((key) => {
    return <MarkerPopup relative={relatives[key]} />;
  });
}

function MarkerPopup(props) {
  let relProp = props.relative;
  relProp.birthLocation = new Location(
    relProp.birthLocation.latitude,
    relProp.birthLocation.longitude
  );
  const relative = Relative.objectInstance(relProp);

  const coords = relative.getCoords();
  const pid = relative.pid;
  const name = relative.name;
  const relationship = relative.relationship;
  const birthplace = relative.birthplace;

  const uniqueKey = `${relationship} ${name} - ${birthplace}: ${coords[0]}, ${coords[1]};`;

  const fsPersonUrl = "https://familysearch.org/tree/person/" + pid

  return (
    <Marker position={coords} key={uniqueKey}>
      <Popup>
        <div className="popup">
          <p className="attribute"><span>{"Name: "}</span>
            <a href={fsPersonUrl} target="_blank" rel="noopener noreferrer">{name}</a>
          </p>
          <p className="attribute"><span>{"Relationship: "}</span>{relationship}</p>
          <p className="attribute"><span>{"Born in: "}</span>{birthplace}</p>
        </div>
      </Popup>
    </Marker>
  );
}

export function MapComponent(props) {
  const [map, setMap] = useState(null);
  const isDesktopOrLaptop = useMediaQuery(
    { minDeviceWidth: 1224 }
  );
  const zoomControl = useRef();
  let centerOfMap = [26, 0];
  let initZoom = 3;

  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [13, 41],
    popupAnchor: [0, -41],
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  // FUTURE - this works once we figure out how to fix update issue with heatmap
  // useEffect(function centerOnBirthplace() {
  //   if (markerPoints && "rel1" in markerPoints) {
  //     const userBirthLocation = markerPoints.rel1.birthLocation;
  //     const userBirthCoords = [
  //       userBirthLocation.latitude,
  //       userBirthLocation.longitude,
  //     ];
  //     centerOfMap = userBirthCoords;
  //     initZoom = 4.75;
      
  //     if (map) {
  //       map.flyTo(new LatLng(centerOfMap[0], centerOfMap[1]), initZoom);
  //     }
  //   }
  // }, [props.markerPoints])

  useEffect(function createZoomControl() {
    // Create new zoom control at bottom-right (to override default top-left one)
    if (map) {
      // Remove the zoom control if there is one that already exists
      if (zoomControl.current) {
        map.removeControl(zoomControl.current); 
        zoomControl.current = null;
      }
      
      let control = L.control.zoom({position: "topright"});
      zoomControl.current = control;
      zoomControl.current.addTo(map);
    }
  }, [map])

  return (
    <MapContainer
      ref={setMap}  
      center={centerOfMap}
      zoom={initZoom}
      minZoom={isDesktopOrLaptop ? DESKTOP_MIN_ZOOM : MOBILE_MIN_ZOOM}
      maxZoom={10}
      zoomSnap={0.25}
      zoomDelta={1}
      wheelPxPerZoomLevel={40}
      maxBounds={MAP_BOUNDS}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <HeatmapLayer
        // fitBoundsOnLoad                     // Overrides zoom on MapContainer
        // fitBoundsOnUpdate                   // Overrides zoom on MapContainer
        points={props.heatmapPoints}
        longitudeExtractor={(m) => m[1]}
        latitudeExtractor={(m) => m[0]}
        intensityExtractor={(m) => 1}

        useLocalExtrema={false}
        radius={22}
        max={4}
      />

      {props.markerPoints && <Markers relatives={props.markerPoints} />}
    </MapContainer>
  );
}
