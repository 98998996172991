// const API_URL = "https://apibeta.familysearch.org/platform/tree/ancestry"; // Staging
const API_URL = 'https://www.familysearch.org/platform/tree/ancestry'; // Production

export const NUM_GENERATIONS = 6;

export async function getBirthplaces(userInfo) {
  const pid = userInfo.pid;
  const authToken = userInfo["auth-token"];

  const queryString = new URLSearchParams({
    person: pid,
    generations: NUM_GENERATIONS,
    personDetails: "true",
  }).toString();

  const url = `${API_URL}?${queryString}`;

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + authToken,
        Accept: "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle any errors
    console.error("Error retrieving birthplaces from FamilySearch:", error);
    throw new Error("Error retrieving birthplaces from FamilySearch:", {cause: error})
  }
}
