import {
  postPersonData,
  getPersonalPlaces,
  getSessionPlaces,
} from "../model/services/api";
import { Relationship } from "../model/Relationship";
import { Location } from "../model/Location";
import { User } from "../model/User";
import { Relative } from "../model/Relative";
import { NUM_GENERATIONS } from "../model/services/familySearchData";

export async function saveAndLoadData(userInfo, rawPersonData) {
  await savePersonData(rawPersonData, userInfo);
  return loadPersonData(userInfo);
}

export async function loadPersonData(userInfo) {
  const sessionId = userInfo["session-id"];
  const pid = userInfo.pid;
  const markers = await getPersonalPlaces(pid);
  const heatmapPoints = await getSessionPlaces(sessionId);
  return { heatmapPoints: heatmapPoints, personalMarkers: markers };
}

export async function savePersonData(rawPersonData, userInfo) {
  const personData = preparePersonData(rawPersonData, userInfo);
  await postPersonData(personData);
}

function preparePersonData(relativeData, userData) {
  let relatives = {};
  for (const rel of relativeData) {
    const birthLocation = new Location(rel.latitude, rel.longitude);
    const ascNum = rel.ascNum;
    const relationship = getRelationship(ascNum);
    relatives[`rel${ascNum}`] = new Relative(
      rel.pid,
      rel.name,
      relationship,
      rel.birthplace,
      birthLocation
    );
  }

  const now = Date.now();
  return new User(
    userData.pid,
    userData["display-name"],
    userData.email,
    now,
    userData["session-id"],
    relatives
  );
}

function getRelationship(ascNum) {
  const relAscNums = getRelationshipAscNums();
  let relationship = null;

  for (const rel in relAscNums) {
    if (relAscNums[rel].includes(ascNum)) {
      relationship = Relationship[rel]
    }
  }

  return relationship;
}

function getRelationshipAscNums() {
  let relAscNums = {}

  // Generation 1
  let startAscNum = 1
  let relsInGeneration = 1
  relAscNums.Self = [1]
  relAscNums.Father = [2]
  relAscNums.Mother = [3]

  // Must update startAscNum each generation for ascendancy numbers to be calculated correctly
  function calcGrandfatherAscNums(v, k) {return (k * 2) + startAscNum}
  function calcGrandmotherAscNums(v, k) {return (k * 2) + startAscNum + 1}

  // Generation 2
  startAscNum = 4
  relsInGeneration = 2
  relAscNums.Grandfather = Array.from({length: relsInGeneration}, calcGrandfatherAscNums)
  relAscNums.Grandmother = Array.from({length: relsInGeneration}, calcGrandmotherAscNums)

  // Generation 3+
  for (let gen = 3; gen <= NUM_GENERATIONS; gen++) {
    startAscNum = 2 ** gen
    relsInGeneration = startAscNum / 2

    relAscNums[`Gr${gen - 2}Grandfather`] = Array.from({length: relsInGeneration}, calcGrandfatherAscNums)
    relAscNums[`Gr${gen - 2}Grandmother`] = Array.from({length: relsInGeneration}, calcGrandmotherAscNums)
  }

  return relAscNums
}
