import { useTestData } from "../data/testData.js";
import { sessionIdExists } from "../model/services/api";

export const AVAILABLE_CHARS = "ABCDEFGHJKMNPQRSTUVWXYZ23456789"; // Confusing characters removed (I, L, and 1; 0 and O)

export async function generateUnusedSessionId() {
  let sessionId = "";
  let exists = "true";
  do {
    sessionId = generateSessionId();
    exists = (useTestData) ? false : await sessionIdExists(sessionId);
    if (exists == null)
      throw new Error("The call, 'sessionIdExists', ran into an error.");
  } while (exists);
  return sessionId;
}

function generateSessionId() {
  const SESSION_ID_LENGTH = 6;

  let sessionId = "";
  for (let i = 0; i < SESSION_ID_LENGTH; i++) {
    const curCharIndex = Math.floor(Math.random() * AVAILABLE_CHARS.length); // Randomly choose an index
    const curChar = AVAILABLE_CHARS[curCharIndex];
    sessionId += curChar;
    if (i === 2) sessionId += "-";
  }

  return sessionId;
}
