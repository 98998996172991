import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import "./session.css";

import OtpInput from "react-otp-input";

/**
 * This React component will:
 * 1. prompt user for sessionID
 *    ON ENTER - - validate sessionID
 *      IF FILLED - - set cookie using props.cookieName and navigate to next page (props.navNextPage())
 *      IF NOT FILLED - - nothing
 */
export function Session({ numBoxes, navNextPage, cookieName }) {
  const [sessionId, setSessionId] = useState("");

  function handleSession(e) {
    if (sessionId.length === numBoxes) {
      const cookies = new Cookies();
      let actualSessionId = sessionId;
      actualSessionId = 
        actualSessionId.slice(0, 3) + "-" + actualSessionId.slice(3);
      const cookie = { [`${cookieName}`] : actualSessionId };
      cookies.set(cookieName, cookie);
      navNextPage();
    }
  }

  function onSessionIdChange(sessionId) {
    sessionId = sessionId.toUpperCase();
    setSessionId(sessionId);
  }

  function renderInput(props) {
    const oldHandleChange = props.onChange;
    props.onChange = (e) => {
      if (e.target.value.match(/[0-1oilOIL]/g)) {
        e.preventDefault();
        return;
      }
      oldHandleChange(e);
    };
    return (
      <div className="inputWrapper">
        <input {...props} />
      </div>
    );
  }

  useEffect(() => {
    if (sessionId.length === numBoxes) {
      document.querySelector("#JoinBtn")?.focus();
    }
  }, [sessionId, numBoxes]);

  function onJoinButtonKeyDown(e) {
    let lastInput;
    switch (e.key) {
      case "ArrowUp":
      case "ArrowLeft":
      case "Backspace":
        e.preventDefault();
        lastInput = Array.from(document.querySelectorAll(".box"))?.at(-1);
        lastInput?.focus();
        break;

      default:
        break;
    }
  }

  return (
    <div id="container">
      <h3 id="session-title">Please enter your session ID:</h3>
      <div id="boxes">
      <OtpInput
        value={sessionId}
        onChange={onSessionIdChange}
        numInputs={numBoxes}
        renderInput={renderInput}
        inputStyle="box"
        shouldAutoFocus
      />
      </div>
      <button
        onKeyDown={onJoinButtonKeyDown}
        onClick={handleSession}
        id="JoinBtn"
      >
        JOIN
      </button>
    </div>
  );
}
