export class Location {
  constructor(lat, lon) {
    this.latitude = lat;
    this.longitude = lon;
  }
  static objectInstance(loc) {
    return new Location(loc.latitude, loc.longitude);
  }

  getCoords() {
    return [this.latitude, this.longitude];
  }
}
