export const Relationship = Object.freeze({
  Self: "Self",
  Father: "Father",
  Mother: "Mother",
  Grandfather: "Grandfather",
  Grandmother: "Grandmother",
  Gr1Grandfather: "Great-grandfather",
  Gr1Grandmother: "Great-grandmother",
  Gr2Grandfather: "Great-great-grandfather",
  Gr2Grandmother: "Great-great-grandmother",
  Gr3Grandfather: "Great-great-great-grandfather",
  Gr3Grandmother: "Great-great-great-grandmother",
  Gr4Grandfather: "Great-great-great-great-grandfather",
  Gr4Grandmother: "Great-great-great-great-grandmother",
  Gr5Grandfather: "Great-great-great-great-great-grandfather",
  Gr5Grandmother: "Great-great-great-great-great-grandmother",
  Gr6Grandfather: "Great-great-great-great-great-great-grandfather",
  Gr6Grandmother: "Great-great-great-great-great-great-grandmother"
});
