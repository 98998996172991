import "./App.css";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Home } from "./../view/pages/home/home.js";
import { Login } from "./../view/pages/login/login.js";
import { UserSession } from "./../view/pages/userSession/userSession.js";
import { Map } from "./../view/pages/map/map.js";
import { HostSession } from "./../view/pages/hostSession/hostSession.js";
import { Host } from "./../view/pages/host/host.js";
import { Error } from "../view/components/error/error.js";
import { postBugReport } from "../model/services/api";
import { ReportBug } from "../view/pages/report-bug/report-bug";
import assign100dvh from "./fullViewportPresenter";

function App() {
  assign100dvh();

  return (
    <ErrorBoundary 
      FallbackComponent={Error}
      onError={(error) => 
        {
          const causeMessage = (error?.cause?.message) ? error.cause.message : "(Cause message not given)"
          const causeStack = (error?.cause?.stack) ? error.cause.stack : "Stack message not given"
          postBugReport(`OurBeginnings had an error: ${error.message} ${causeMessage}`, causeStack)
        }
      }
      onReset={() => window.location.href = "/"}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user-session" element={<UserSession />} />
        <Route path="/map" element={<Map />} />
        <Route path="/host-session" element={<HostSession />} />
        <Route path="/host" element={<Host />} />
        <Route path="/report-bug" element={<ReportBug />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
