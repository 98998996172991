import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { SESSION_ID_COOKIE_KEY } from "../userSession/userSession";
import { getParams } from "../../../presenter/utils.js";
import "../home/home.css";
import { getTestLoginCookie, useTestData } from "../../../data/testData.js";

const LOGIN_COOKIE_KEY = "login";
const ONE_HOUR_IN_SECS = 3600;
const LOGIN_COOKIE_OPTIONS = { maxAge: ONE_HOUR_IN_SECS, secure: true };

/**
 * This React component will:
 * 1. check if there is loginCookie
 *    IF SO - - navigate straight to Session component (/session)
 *    IF NOT - - navigate to OAuth for logging in
 */
export function Login() {

  useEffect(
    function onStart() {
      const cookies = new Cookies();

      // Check query for all parts of cookie from query - - if validated, set the cookie
      if (window.location.search) {
        const queryParams = new URLSearchParams(window.location.search);
        const paramsToCheck = ["auth-token", "pid", "display-name", "email"];
        const { missingParams, cookie } = getParams(queryParams, paramsToCheck);

        if (missingParams.length)
          throw new Error(`Cookie info passed in query is invalid -> missing attributes in query (${missingParams})`);
        else cookies.set(LOGIN_COOKIE_KEY, cookie, LOGIN_COOKIE_OPTIONS);
      }

      let loginCookie = cookies.get(LOGIN_COOKIE_KEY);
      if (loginCookie) navNextPage();
      else {
        if (useTestData) returnFromOAuth(); 
        else window.location.href = getOAuthFSUrl();
      }
    },
    []
  );

  function getOAuthFSUrl() {
    const curUrl = new URL(window.location.href);

    let redirectUri = curUrl;
    redirectUri.pathname = "login";
    redirectUri.search = "";
    redirectUri = encodeURIComponent(redirectUri.toString());

    let oauthUrl = curUrl;
    oauthUrl.hostname =
      "oauthfs." +
      oauthUrl.hostname.replace("www.", "").replace("ourbeginnings.", "");
    oauthUrl.pathname = "login.html";
    oauthUrl.search = `redirect_uri=${redirectUri}`;
    oauthUrl = oauthUrl.toString();

    return oauthUrl;
  }

  function navNextPage() {
    // If session ID cookie exists, navigate to Map page (/map); otherwise, navigate to Session page (/session)
    const cookies = new Cookies();
    const sessionIdCookie = cookies.get(SESSION_ID_COOKIE_KEY);

    if (sessionIdCookie) window.location.href = "/map";
    else window.location.href = "/user-session";
  }

  // FUNCTION FOR TESTING - - simulates having gone to OAuth and returned with cookie info in query
  function returnFromOAuth() {
    // Get test login cookie
    const { authToken, pid, displayName, email } = getTestLoginCookie();

    // Set those in the query params and change page to '/login-return?...'
    window.location.href = `/login?auth-token=${authToken}&pid=${pid}&display-name=${displayName}&email=${email}`;
  }

  return <></>;
}

export { LOGIN_COOKIE_KEY };
